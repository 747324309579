<template>
  <div class="">
    <v-date-picker v-model="selected" mode="dateTime" :max-date="this.maxDateProp">
      <template v-slot="{ inputValue, inputEvents }">
        <b-form-group :label="label">
          <b-form-input
            :value="datetime24H(inputValue)"
            @focusin="inputEvents.focusin"
            @focusout="selected = datetime12H($event.target.value)"
            @keyup.enter="selected = datetime12H($event.target.value)"
            autocomplete="off"
            placeholder="yyyy-mm-dd HH:MM (24H)"
            required
          >
          </b-form-input>
        </b-form-group>
      </template>
      <template #footer>
        <div class="p-2 button-date-now">
          <b-button
            block
            variant="primary"
            size="sm"
            @click="selected = dateNowString()"
            >Now</b-button
          >
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatetimeComponent",
  props: {
    value: {},
    label: {
      required: true,
    },  
    maxDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", moment(value).toString());
      },
    },
    maxDateProp() {
    const userData = JSON.parse(localStorage.getItem('session_auth'));
    const isRole = userData.role_id === 5;
    const isCreatingNewspaper = this.$route.name === "newspaperCreate";
    const isEditingNewspaper = this.$route.name === "newspaperEdit";  
    if (this.maxDate && isRole && (isCreatingNewspaper || isEditingNewspaper)) {
      return new Date(new Date().setDate(new Date().getDate() + 1));
    } else {
      return null;
    }
},
}}
</script>
