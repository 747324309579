var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('v-date-picker', {
    attrs: {
      "mode": "dateTime",
      "max-date": this.maxDateProp
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var inputValue = _ref.inputValue,
          inputEvents = _ref.inputEvents;
        return [_c('b-form-group', {
          attrs: {
            "label": _vm.label
          }
        }, [_c('b-form-input', {
          attrs: {
            "value": _vm.datetime24H(inputValue),
            "autocomplete": "off",
            "placeholder": "yyyy-mm-dd HH:MM (24H)",
            "required": ""
          },
          on: {
            "focusin": inputEvents.focusin,
            "focusout": function ($event) {
              _vm.selected = _vm.datetime12H($event.target.value);
            },
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              _vm.selected = _vm.datetime12H($event.target.value);
            }
          }
        })], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "p-2 button-date-now"
        }, [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function ($event) {
              _vm.selected = _vm.dateNowString();
            }
          }
        }, [_vm._v("Now")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }